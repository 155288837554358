<template>
    <div class="spec-data">
        <!-- 规格名称 -->
        <div class="spec-name">{{specName}}</div>
        <!-- 选项 -->
        <ul class="spec-list">
            <li v-for="(item, index) in specList"
                :key="index">
                <a href="javascript:;"
                    @click="handleClick(item)"
                    :class="{
                        'spec-item': value == item
                    }">
                    {{item}}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            value: [ String, Number ],
            specName: String,
            specList: Array,
        },
        methods: {
            handleClick(value) {
                this.$emit('input', value);
                this.$emit('change', value);
            }
        },
    }
</script>

<style scoped>
.spec-list {
    padding-left: 20px;
    padding-right: 40px;
}
.spec-list > li {
    margin: 10px 0;
    text-align: center;
}
.spec-list > li > a {
    display: inline-block;
    min-width: 200px;
    padding: 4px 20px;
    border-radius: 30px;
    color: #606266;
}
.spec-list > li > .spec-item {
    background-color: #1142a8;
    color: #FFF;
}
</style>